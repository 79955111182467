<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">

    <v-row no-gutters class="mx-2">

      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <v-btn
            class="btn-primary"
            color="primary"
            @click="toggleImportDialog(true)">
            {{ $t('suppliers.tabs.certifications.buttons.add') }}
          </v-btn>
        </v-row>
      </v-col>

      <!-- Info -->
      <v-col cols="12">

        <v-row no-gutters>

          <v-col cols="12">
            <CertificationsDatatable
              v-if="!showDialog.import"
              :URL_API="URL_API"
            />
          </v-col>

        </v-row>
      </v-col>
    </v-row>

    <!-- Upload dialog -->
    <UploadCertificationDialog
      v-if="showDialog.import"
      :value="showDialog.import"
      :URL_API="URL_API.UPLOAD"
      @close="(needRefresh) => toggleImportDialog(false, needRefresh)"
    />
  </v-card>
</template>

<script>

export default {
  name: "SupplierCertificationsTab",

  components: {
    UploadCertificationDialog: () => import("@/components/Common/Certifications/UploadCertificationDialog"),
    CertificationsDatatable: () => import("@/components/Common/Certifications/CertificationsDatatable"),
  },

  mixins: [],

  props: {
    supplier: { type: Object, default: () => null, required: true },

  },

  data() {
    return {

      showDialog: {
        import: false,
      },

      URL_API: {
        GET_ALL: `/suppliers/${this.supplier.id}/certifications`,
        UPLOAD: `/suppliers/${this.supplier.id}/certifications`,
        EDIT: `/suppliers/${this.supplier.id}/certifications`,
        DELETE: `/suppliers/${this.supplier.id}/certifications`,
        DOWNLOAD: `/suppliers/${this.supplier.id}/certifications`
      }
    }
  },

  methods: {
    toggleImportDialog(val, needRefresh = false) {
      this.showDialog.import = val;
    },
  },
}
</script>

<style scoped>

</style>
